.article-card {

    &__author {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        bottom: -10px;
        right: 10px;
        @screen mp {
            bottom: -30px;
            right: 20px;
        }
    }

    &__brandlogo {
        width: 60px;
        max-width: 33%;
        margin: 10px;
    }
}
